// @ts-strict-ignore
import { GetSidebarItemFn } from "components/layout/Sidebar/sidebar.types";
import { UserAreaName } from "types/user.types";

export const sidebarItems: GetSidebarItemFn[] = [
    () => ({
        _type: "link",
        route: "dashboard",
        title: "Dashboard",
        icon: "lely-icon-dashboard",
    }),
    ({ userAreas }) => ({
        _type: "link",
        route: "technicians",
        title: userAreas.some((area) => (["TSS_Technician_Core"] as UserAreaName[]).includes(area))
            ? "My Overview"
            : "Technicians",
        icon: "lely-icon-group",
    }),
    () => ({
        _type: "menu",
        title: "Performance",
        icon: "lely-icon-graph",
        links: [
            () => ({
                _type: "link",
                route: "preventiveMaintenanceOverdue",
                title: "Preventive Maintenance Overdue",
                icon: "lely-icon-robot",
            }),
            () => ({
                _type: "link",
                route: "averageRepairTimes",
                title: "Average Repair Times",
                icon: "lely-icon-clock",
            }),
            () => ({
                _type: "link",
                route: "repeatVisitCases",
                title: "Repeat Visit Cases",
                icon: "lely-icon-ongoing",
            }),
            () => ({
                _type: "link",
                route: "longRepairs",
                title: "Long Repairs",
                icon: "lely-icon-clock-interval",
            }),
            () => ({
                _type: "link",
                route: "repairsPerAstronaut",
                title: "Repairs per Astronaut",
                icon: "lely-icon-astronaut",
            }),
            () => ({
                _type: "link",
                route: "abnormalPreventiveMaintenanceTimes",
                title: "Abnormal Preventive Maintenance Times",
                icon: "lely-icon-diagram",
            }),
            () => ({
                _type: "link",
                route: "manHoursHigh",
                title: "Man-Hours High",
                icon: "lely-icon-calendar",
            }),
            () => ({
                _type: "link",
                route: "productiveTime",
                title: "Productive Time",
                icon: "lely-icon-chart",
            }),
            () => ({
                _type: "link",
                route: "mandatoryUpdates",
                title: "Mandatory Updates",
                icon: "lely-icon-refresh",
            }),
        ],
    }),
    () => ({
        _type: "link",
        route: "softwareVersionOverview",
        title: "SW Version Overview",
        icon: "lely-icon-table",
    }),
    () => ({
        _type: "link",
        route: "parts",
        title: "Parts Overview",
        icon: "lely-icon-gear",
    }),
    () => ({
        _type: "link",
        route: "profitability",
        title: "Profitability",
        icon: "lely-icon-income",
    }),
    () => ({
        _type: "menu",
        title: "Engineering",
        icon: "lely-icon-graph",
        links: [
            () => ({
                _type: "link",
                route: "connectionPerformance",
                title: "Connection Performance",
                icon: "lely-icon-human",
            }),
            () => ({
                _type: "link",
                route: "mfpBimodality",
                title: "MFP Bimodality",
                icon: "lely-icon-diagram",
            }),
            () => ({
                _type: "link",
                route: "mfpPlotter",
                title: "MFP Plotter",
                icon: "lely-icon-graph",
            }),
            () => ({
                _type: "link",
                route: "TDSLinerAdvice",
                title: "TDS Liner Advice",
                icon: "lely-icon-diagram",
            }),
        ],
    }),
    () => ({
        _type: "link",
        route: "strategicOverview",
        title: "Strategic Overview",
        icon: "lely-icon-graph",
    }),
    () => ({
        _type: "link",
        route: "serviceAreaSupport",
        title: "Service Area Support",
        icon: "lely-icon-location",
    }),
    () => ({
        _type: "link",
        route: "attentionCustomers",
        title: "Attention Customers",
        icon: "lely-icon-human",
    }),
    ({ userAreas, developerMode }) =>
        userAreas.some((area) => (["TSS_Core"] as UserAreaName[]).includes(area)) || developerMode
            ? {
                  _type: "link",
                  route: "criticalAlarmsAnalysis",
                  title: "Critical Alarms Analysis",
                  icon: "lely-icon-diagram",
              }
            : null,
    () => ({
        _type: "link",
        route: "devices",
        title: "Devices",
        icon: "lely-icon-robot",
    }),
    () => ({
        _type: "link",
        route: "horizonUsage",
        title: "Horizon Usage",
        icon: "lely-icon-desktop",
    }),
    () => ({
        _type: "link",
        route: "customers",
        title: "Customers List",
        icon: "lely-icon-tasks",
    }),
    ({ userAreas, developerMode }) =>
        userAreas.some((area) => (["FMS_Beta"] as UserAreaName[]).includes(area)) || developerMode
            ? {
                  _type: "link",
                  route: "farmScan",
                  routeOptions: {
                      searchParams: {
                          beta: true,
                      },
                  },
                  title: "Farm Scan (Beta)",
                  icon: "lely-icon-farm",
              }
            : null,
    () => ({
        _type: "link",
        route: "agreements",
        title: "Agreements",
        icon: "lely-icon-robot",
    }),
    () => ({
        _type: "link",
        route: "chameleon",
        title: "Chameleon",
        icon: "lely-icon-farm",
    }),
];
